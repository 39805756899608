import { assert } from '@integrabeauty/assert';
import type * as GladlyTypes from '@integrabeauty/gladly-types';

/**
 * Fired by Gladly whenever agent availability changes.
 *
 * This is not dispatched on page load. This only happens in the rare case someone opens a page
 * while Gladly is in one state, then Gladly state changes. So basically right when customer service
 * agents come online or go offline. So basically this is an incredibly rare event.
 *
 * @todo nothing seems to be listening for this event, we should consider removing it
 */
type GladlyAvailabilityChangedEvent = CustomEvent<{
  /**
   * Corresponds to Gladly's availability state.
   */
  availability?: GladlyTypes.Availability;
}>;

/**
 * @todo document me
 */
type GladlyCampaignTriggeredEvent = CustomEvent<{
  actions?: GladlyTypes.CampaignAction[];
  id?: string;
}>;

/**
 * Fired by Gladly whenever a user's conversation with a customer service agent ends.
 */
type GladlyConversationEndedEvent = CustomEvent<{
  source?: GladlyTypes.ConversationEndedReason;
}>;

type GladlyConversationStartedEvent = CustomEvent<undefined>;

/**
 * Fired some time early in the Gladly conversation after some automated steps happen, such as after
 * acquiring the customer's email address, so that the info can be used to assist the customer
 * service agent in looking up the customer's order(s).
 */
type GladlyCustomerOnboardedEvent = CustomEvent<undefined>;

/**
 * Fired when initializing Gladly. If error is set, then Gladly failed to init. If error is not set,
 * then Gladly successfully initialized.
 */
type GladlyInitCompletedEvent = CustomEvent<{ error?: Error; }>;

/**
 * Fired when a user receives a message from a customer service agent.
 *
 * Unfortunately Gladly makes it next to impossible to observe the text of the message.
 */
type GladlyMessageReceivedEvent = CustomEvent<{
  initiator_id: string;
  initiator_type: string;
  type: string;
}>;

/**
 * Fired by Gladly whenever a user sends a message to a customer service agent.
 *
 * Unfortunately Gladly makes it almost impossible to observe the text of the message that was sent.
 */
type GladlyMessageSentEvent = CustomEvent<{
  campaign_id?: string;
  is_initial_message?: boolean;
  type?: string;
}>;

/**
 * Fired when a user performs some kind of quick-action. See Gladly docs for details.
 */
type GladlyQuickActionSelectedEvent = CustomEvent<unknown>;

/**
 * @todo document me
 */
type GladlySearchResultSelectedEvent = CustomEvent<{
  /**
   * @todo what is the type of label?
   */
  label: any;
}>;

/**
 * Fired when the Gladly element is hidden.
 */
type GladlySidekickClosedEvent = CustomEvent<unknown>;

/**
 * Fired when the Gladly element is shown.
 */
type GladlySidekickOpenedEvent = CustomEvent<unknown>;

declare global {
  /**
   * Globally tracks whether we have started to initialize Gladly. This helps coordinate across
   * multiple independent javascript modules that each use this library that may be loaded
   * separately.
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var gladly_init_started: boolean;

  interface WindowEventMap {
    'gladly-availability-changed': GladlyAvailabilityChangedEvent;
    'gladly-campaign-triggered': GladlyCampaignTriggeredEvent;
    'gladly-conversation-ended': GladlyConversationEndedEvent;
    'gladly-conversation-started': GladlyConversationStartedEvent;
    'gladly-customer-onboarded': GladlyCustomerOnboardedEvent;
    'gladly-init-completed': GladlyInitCompletedEvent;
    'gladly-message-received': GladlyMessageReceivedEvent;
    'gladly-message-sent': GladlyMessageSentEvent;
    'gladly-quick-action-selected': GladlyQuickActionSelectedEvent;
    'gladly-search-result-selected': GladlySearchResultSelectedEvent;
    'gladly-sidekick-closed': GladlySidekickClosedEvent;
    'gladly-sidekick-opened': GladlySidekickOpenedEvent;
  }
}

/**
 * A partial typing of the response body returned by fetching Gladly config.
 */
interface DynamicConfig {
  availability: string;
  enabled: boolean;
  featureFlags: Record<string, any>;
  officeHours: Record<string, any>;
  percentageRollout: number;
}

/**
 * This works similar to the internal logic used by Gladly to fetch config both on initialization
 * and later when polling when the business is unavailable.
 *
 * This can break at any time without notice.
 *
 * This does not handle errors. The returned promise is rejected for any kind of error.
 *
 * Because this is a cross origin request we intentionally do not provide headers. Older browsers
 * such as Safari 10, which we wish to support, throw errors when using headers.
 *
 * We specify no-cache just like the minified code from cdn.gladly.com/assets/chat-sdk. Otherwise
 * the request is cached and the first fetch response body will determine availability.
 *
 * This function always errors because the fetch always errors when fetching from a domain other
 * than langehair.com. The caller should check if called from frame in https://langehair.com and
 * avoid calling this when that is not the case.
 */
async function fetchDynamicConfig() {
  const url = 'https://cdn.gladly.com/orgs/configs/chat/langehair.com-dynamic.json';
  const response = await fetch(url, { cache: 'no-store', mode: 'cors' });
  assert(response.ok, `Received status ${response.status} fetching Gladly dynamic config`);
  return <Promise<DynamicConfig>>response.json();
}

/**
 * This is an adaptation of the base tag. The code was de-minimized a bit, and then wrapped in a
 * real promise that resolves when the script tag is loaded or otherwise rejects.
 *
 * Returns whether Gladly loaded. There are 3 outcomes:
 * * resolve true: Gladly loaded, either because it was already loaded or just now loaded
 * * resolve false: Gladly was not loaded because the business is unavailable or there was an error
 *   determining availability
 * * rejection: an error occurred and it is not clear whether Gladly loaded
 *
 * There is a really complicated issue. Shopify and other vendors have decided it is ok to hook
 * fetch. We have no way to trivially prevent this interception. Gladly provides no means to
 * trivially specify how it should load code so we cannot override its communications strategy. One
 * solution would be to embed Gladly in an iframe. However, Gladly is hardcoded to render itself
 * into its containing document and does not understand how to render into a parent frame document.
 * We could make the iframe visual but this would be really messy and involve a huge amount of even
 * greatly complexity with modality and cursor interactions and element overlays and all the rest.
 * For now I have made the decision that using a same origin iframe is not a satisfactory solution.
 *
 * Then the issue is further complicated by Gladly's hardcoded automatic polling logic. Once the
 * script loads using dynamic configuration mode, and is able to successfully load and init, and
 * then sees that the business is currently unavailable, it will indefinitely poll Gladly for an
 * updated config to see if the business is suddenly available. It does not do any optimized check
 * based on time until available. It just sits there and makes json requests forever. Every single
 * one of those is then hooked by the vendors, and then we get tons of periodic network failures for
 * any user who keeps a page open for even a couple minutes. We also get script errors logged to
 * Sentry for vendor code that incorrectly hooks fetch, including Shopify's own code. All just so
 * that Shopify can do things like detect add to carts via requests to the cart ajax api which we
 * are not even using.
 *
 * This issue is further complicated by the fact that the polling is pointless. We only attempt to
 * initialize Gladly once on page load, and only render UI that leads to Gladly being visible in the
 * initial availability check. The polling is pointless because even when Gladly becomes available
 * it will never appear. Our logic only renders if available on page load.
 *
 * The issue is further complicated by Gladly wrapping the timer id for the polling within function
 * scope, making it non-trivial to interfere and cancel the polling.
 *
 * The current solution is the following. We are going to reach into the internals of the way the
 * Gladly SideKick client works. We are going to perform an unhooked fetch of the current
 * availability. If we are able to issue the request and see that Gladly is unavailable, we are
 * going to resolve the loading promise with a value that indicates that while there was no error
 * in loading, that Gladly should not be loaded.
 *
 * This solution involves an extra request on page load. I guess we do not have much of a choice.
 *
 * This solution is open to breakage the moment Gladly performs some kind of code change. We will
 * have to update here in response once the breakage is observed (Gladly does not really provide
 * great notices of when it deploys updates so waiting for things to break is the only choice).
 */
export async function load(allowedHostnames: string[], continueOnUnavailable = false) {
  if (location.protocol !== 'https:' || !allowedHostnames.includes(location.hostname)) {
    return false;
  }

  // We are already loaded if this exists.
  if (window.Gladly) {
    return true;
  }

  let config: DynamicConfig;
  try {
    config = await fetchDynamicConfig();
  } catch (error) {
    // we log an error and intentionally continue
    console.warn('Failed to fetch Gladly dynamic config:', error);
  }

  if (!config) {
    return false;
  }

  if (config.availability !== 'AVAILABLE') {
    if (continueOnUnavailable) {
      // even though gladly is not available, continue with gladly initialization. this particular
      // situation happens on the help page.
    } else {
      return false;
    }
  }

  return new Promise<boolean>((resolve, reject) => {
    let initialArguments: any;

    // Some kind of esoteric callback-chain queue.
    const queue: any[] = [];

    // We never use Gladly's QA feature, so I removed all the bits about checking whether Gladly is
    // loaded in QA mode and hardcoded the production url.

    const url = 'https://cdn.gladly.com/chat-sdk/widget.js';

    window.Gladly = {
      init: function () {
        initialArguments = arguments;
        const syntheticPromise: GladlyTypes.SyntheticPromise = {
          then: function (t) {
            queue.push({ type: 't', next: t });
            return syntheticPromise;
          },
          catch: function (t) {
            queue.push({ type: 'c', next: t });
            return syntheticPromise;
          }
        };
        return syntheticPromise;
      }
    };

    // This is an undocumented global. It looks like what happens is that once the script loads it
    // calls this function. This function then deletes itself and sets up the global Gladly
    // instance.

    window.__onHelpAppHostReady__ = function (sidekick: GladlyTypes.SidekickClient) {
      delete window.__onHelpAppHostReady__;
      window.Gladly = sidekick;
      Gladly.loaderCdn = url;

      // When we later Gladly.init, this replays the queued callbacks in order to bootstrap. Since
      // we have customized the loading and defer the resolution of this promise until script load
      // settles, the queue is expected to be empty, so this for loop will noop.

      if (initialArguments) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        for (let syntheticPromise = sidekick.init.apply(sidekick, initialArguments), n = 0;
          n < queue.length; n++) {
          const a = queue[n];
          syntheticPromise = 't' === a.type ?
            syntheticPromise.then(a.next) :
            syntheticPromise.catch(a.next);
        }
      }
    };

    function appendScript() {
      try {
        const firstScript = document.getElementsByTagName('script')[0];
        const script = document.createElement('script');
        script.async = !0;
        script.src = url + '?q=' + (new Date()).getTime();

        // This is our injected code. We want to settle the outer promise once the script loads.
        // This ensures that we defer. Although based on the way the base tag init code works, we
        // could just resolve immediately after we call appendScript.
        script.addEventListener('load', () => {
          resolve(true);
        });
        script.addEventListener('error', () => reject(new Error('Failed to load ' + url)));
        firstScript.parentNode.insertBefore(script, firstScript);
      } catch (error) {
        // We injected this. We need to ensure the containing promise settles.
        reject(new Error('Failed to load ' + url));
      }
    }

    appendScript();
  });
}

/**
 * Initializes Gladly. Returns a promise that resolves if Gladly loaded or rejects if there was a
 * loading error.
 *
 * We init Gladly using manual initialization instead of automatic initialization. We init Gladly
 * from multiple places in the code that do not coordinate. As a result, we encounter situations on
 * some page loads where multiple modules try to init Gladly, which leads to the error "Sidekick
 * already initialized" thrown by Gladly's chat sdk. This helper solves that problem by introducing
 * coordination.
 *
 * We sometimes init Glady from different webpack bundles. We are using an event instead of a simple
 * boolean so that we can completely avoid any issues with trying to init Gladly from within
 * different bundles. Events are an easy way to pass messages between bundles without any fancy
 * code. Using a module-scoped boolean does not work because it is per bundle. We do not want to
 * introduce yet another window global, so reusing the lange data layer, which is already a global,
 * works fine.
 *
 * This function cannot be qualified as async because we need to arbitrarily delay resolution until
 * the completion of the Gladly.init promise, but only in certain cases. I chose an event driven
 * implementation over some gimmicky kind of polling mechanism. It does however mean that there is
 * no time limit on when this promise resolves if there is some issue with not receiving the
 * completed event. So the exit condition is not a true guarantee, but it should generally work. It
 * will at least be an idle in flight promise instead of a polling timer microtask.
 */
export async function init(config: Partial<GladlyTypes.SidekickConfig>) {
  return new Promise<void>((resolve, reject) => {
    // Detect whether Gladly has already started initializing based on the presence of the event in
    // the lange data layer. If Gladly has already started, we cannot return immediately because
    // callers are waiting on this promise to resolve to be able to use functions like
    // Gladly.getAvailability, which is not going to work until the initialization completes.

    if (window.gladly_init_started) {
      // If some other init call already triggered a completed event, settle immediately. If the
      // other init has not yet completed, defer our resolve until the other init fires its
      // completed event.

      const initCompleted = window.gladly_event_queue.find(isGladlyInitCompletedEvent);
      if (initCompleted) {
        console.log('[Gladly] detected completed init, short circuiting init');
        if (initCompleted.detail?.error) {
          reject(initCompleted.detail.error);
        } else {
          resolve();
        }
      } else {
        console.log('[Gladly] detected existing pending init, waiting to settle...');
        addEventListener('gladly-init-completed', event => {
          if (event.detail?.error) {
            reject(event.detail.error);
          } else {
            resolve();
          }
        });
      }
    } else {
      // We occasionally see an error where we cannot call init. Perhaps Gladly is classified by
      // an ad blocker or something like that. Unclear. In any event we prefer a more intuitive
      // error message in this case.
      if (!window.Gladly) {
        reject(new Error('Gladly is undefined during init'));
        return;
      }

      window.gladly_init_started = true;

      // Perform the initialization

      // We carry forward a boolean in the completed event so that we can reject if the sibling
      // init call failed.

      // Gladly.init returns a synthetic promise with a then and finally method with alternate
      // arguments if this called before the Gladly script loaded and replaced the value of the
      // Gladly variable. If called after it depends on whatever Gladly does in its loaded script.
      // Be careful trying to interact with this code as if was a normal promise.

      Gladly.init(config).then(() => {
        // Upon initialization, fire the complete event so that duplicate promise listeners resolve
        // as well.

        dispatchEvent(new CustomEvent('gladly-init-completed'));

        try {
          registerGlobalEvents();
        } catch (error) {
          console.warn(error, 'Failed to register global events');
          // suppress, prevent failures here from being fatal
        }

        resolve();
      }).catch((error: any) => {
        // We intentionally do not log anything in this situation. We leave it to the caller who is
        // handling the rejection.

        type Detail = WindowEventMap['gladly-init-completed']['detail'];
        const completedEvent = new CustomEvent<Detail>('gladly-init-completed', {
          detail: { error }
        });
        dispatchEvent(completedEvent);

        // It is important to reject here because callers have logic based on whether an error
        // occurred, and without a rejection will get errors trying to use Gladly after a failed
        // init.

        if (error instanceof Error) {
          reject(error);
        } else {
          reject(new Error('Failed to initialize Gladly'));
        }
      });
    }
  });
}

/**
 * Registers event listeners on the initialized Gladly Sidekick instance. Relays events to our
 * custom global events on window. This way we do not need to have all the tracking code that wants
 * to listen to some of these events be tightly coupled with Gladly.
 */
function registerGlobalEvents() {
  // The strangest thing, where Gladly is defined for purposes of calling Gladly.init, and then
  // here, called from the then() following init, window.Gladly is undefined. This error occurs
  // sufficiently frequently that we may as well just ignore it.
  if (!window.Gladly) {
    console.log('Gladly defined in init but undefined afterward');
    return;
  }

  // Another very strange thing, sometimes Gladly is defined but the `on` method is not. Perhaps
  // this is some kind of failed partial initialization internal to Gladly code. Maybe there is a
  // timing issue and we are supposed to be waiting for some kind of ready callback? There is not
  // much clarity here and Gladly documentation is lacking so for now just bail.
  if (typeof Gladly.on !== 'function') {
    console.log('Gladly.on is not a function');
    return;
  }

  Gladly.on('availability:change', availability =>
    dispatch('gladly-availability-changed', { availability }));
  Gladly.on('campaign:triggered', (id, actions) =>
    dispatch('gladly-campaign-triggered', { actions, id }));
  Gladly.on('conversation:ended', source =>
    dispatch('gladly-conversation-ended', { source }));
  Gladly.on('conversation:started', () => dispatch('gladly-conversation-started'));
  Gladly.on('customer:onboarded', () => dispatch('gladly-customer-onboarded'));
  Gladly.on('message:received', (type, initiatorId, initiatorType) =>
    dispatch('gladly-message-received', {
      initiator_id: initiatorId,
      initiator_type: initiatorType,
      type
    }));
  Gladly.on('message:sent', (isInitialMessage, type, campaignId) =>
    dispatch('gladly-message-sent', {
      campaign_id: campaignId,
      is_initial_message: isInitialMessage,
      type
    }));
  Gladly.on('quick-action:selected', (label, type) =>
    dispatch('gladly-quick-action-selected', { label, type }));
  Gladly.on('search:result-selected', label =>
    dispatch('gladly-search-result-selected', { label }));
  Gladly.on('sidekick:closed', () => dispatch('gladly-sidekick-closed'));
  Gladly.on('sidekick:opened', source => dispatch('gladly-sidekick-opened', { source }));
}

function isGladlyInitCompletedEvent(value: any): value is GladlyInitCompletedEvent {
  return value?.type === 'gladly-init-completed';
}

type Maps = DocumentEventMap & WindowEventMap;

function dispatch<Key extends keyof Maps, T extends Record<string, any> = Maps[Key]>(type: Key,
  detail?: T['detail']) {
  const event = new CustomEvent<T['detail']>(type, { cancelable: false, detail });
  dispatchEvent(event);
}
