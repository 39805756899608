import type { CustomElement } from '@integrabeauty/custom-elements';
import { init, load } from '../../../lib/gladly.js';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders a help widget. This is a floating widget generally located in the bottom right. This is
 * designed to encapsulate Gladly's help widget.
 *
 * This element definition is not loaded in Shopify design mode.
 */
class HelpWidget extends HTMLElement implements CustomElement {
  public shadowRoot!: ShadowRoot;

  readonly dataset!: {
    /**
     * Gladly app id used to initialize Gladly.
     *
     * @example "example.com"
     */
    gladlyAppId: string;
  };

  /**
   * Prevents attempts to load Gladly multiple times in case of multiple connected callbacks.
   */
  private startedScriptLoad = false;
  private onWindowLoadedBound = this.onWindowLoaded.bind(this);
  private onAvailabilityChangeBound = this.onAvailabilityChange.bind(this);
  private windowLoadTimer?: ReturnType<typeof setTimeout>;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    if (getSafariMajorVersion() < 11) {
      console.log('Gladly is not supported in this browser');
      return;
    }

    if (!this.startedScriptLoad) {
      if (document.readyState === 'complete') {
        this.onWindowLoadedBound(null);
      } else {
        addEventListener('load', this.onWindowLoadedBound);
      }

      // TODO: why is there a typescript error here
      // @ts-expect-error unclear
      this.windowLoadTimer = setTimeout(this.onWindowLoadedBound, 5000);
      this.startedScriptLoad = true;
    }
  }

  public disconnectedCallback() {
    const button = this.shadowRoot.querySelector<HTMLElement>('button');
    button?.removeEventListener('click', onOpenGladlyButtonClick);

    removeEventListener('load', this.onWindowLoadedBound);
    clearTimeout(this.windowLoadTimer);
  }

  private render() {
    const buttonGladlyWidget = this.shadowRoot.querySelector<HTMLElement>('button');
    const availability = Gladly.getAvailability();

    if (availability === 'AVAILABLE') {
      buttonGladlyWidget.classList.remove('hidden');
      buttonGladlyWidget.addEventListener('click', onOpenGladlyButtonClick);
    } else {
      buttonGladlyWidget.classList.add('hidden');
      buttonGladlyWidget.removeEventListener('click', onOpenGladlyButtonClick);
    }
  }

  private onWindowLoaded(_event: Event) {
    removeEventListener('load', this.onWindowLoadedBound);
    clearTimeout(this.windowLoadTimer);

    this.setupGladly().catch(error => {
      // This is not captured at the error/warn level because Gladly frequently fails to load.
      console.log('Failed to initialize Gladly', error);
    });
  }

  private onAvailabilityChange() {
    this.render();
  }

  private async setupGladly() {
    // TODO: the allowed hostnames should not be hard coded here

    const didLoad = await load(['langehair.com']);

    // load returns false when there is no error but the preliminary check for availability
    // indicated Gladly was not available. in this case Gladly was not fully loaded. the load
    // function does not even attach the script so that no future requests occur and so that we do
    // not enter the page into a state of indefinitely polling for availability forever but never
    // showing Gladly anyway, even if the Gladly becomes available later, because this element is
    // coded to only check once on load.

    if (!didLoad) {
      return;
    }

    if (!this.dataset.gladlyAppId) {
      throw new Error('Gladly app id is required');
    }

    await init({ appId: this.dataset.gladlyAppId });

    Gladly.on('availability:change', this.onAvailabilityChangeBound);

    this.render();
  }
}

function getSafariMajorVersion() {
  const agent = navigator.userAgent;
  const offsetBrowser = agent.indexOf('Safari');

  if (offsetBrowser !== -1) {
    const offsetVersion = agent.indexOf('Version');
    if (offsetVersion !== -1) {
      const [version] = agent.substring(offsetVersion + 8, offsetBrowser)?.split(' ');
      return parseInt(version, 10);
    }
  }
}

function onOpenGladlyButtonClick(_event: MouseEvent) {
  Gladly.show();
}

declare global {
  interface HTMLElementTagNameMap {
    'help-widget': HelpWidget;
  }
}

if (!customElements.get('help-widget')) {
  customElements.define('help-widget', HelpWidget);
}
